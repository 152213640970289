<template>
  <div class="ds-wrapper relative md:w-8/12 md:max-w-2lg md:mx-auto">
    <div class="expiry-page text-center mt-32 bg-success-50 pt-10 px-4 pb-8 sm:pt-14 sm:px-6 sm:pb-10 rounded-2xl sm:rounded-3xl">
      <div class="check-box bg-success-500 h-16 w-16 sm:h-18 sm:w-18 flex justify-center items-center rounded-full absolute">
        <vs-icon type="flat" icon="done" class="text-shade-white" icon-size="large"></vs-icon>
      </div>
      <h2 class="mb-4 text-shade-black">Payment plan completed!</h2>
      <p class="mb-6 text-body-dark">If you have a question or need help, try these options:</p>
      <div class="grid justify-center gap-y-2">
        <div class="flex items-center gap-x-4">
          <vs-icon type="flat" class="text-neutral-500 material-icons-outlined" icon="school" icon-size="small"></vs-icon>
          <p class="text-body-dark">Try our
            <a :href="faqUrl" class="no-underline hover:underline">help center</a>
          </p>
        </div>
        <div class="flex items-center gap-x-4">
          <vs-icon type="flat" class="text-neutral-500 material-icons-outlined" icon="email" icon-size="small"></vs-icon>
          <p class="text-body-dark">
            <a :href="`mailto:${email}`" class="no-underline hover:underline">Email us</a>
            and quote your payment ID
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      faqUrl: process.env.VUE_APP_NAME == "FlipPay" ? "https://support.flippay.com.au/knowledge/pay-later-customer-resources" : "https://help.rellopay.co",
      email: process.env.VUE_APP_NAME == "FlipPay" ? "support@flippay.com.au" : "support@rellopay.co",
    }
  }
}
</script>
